import styled, { css } from "styled-components"

const StyledMilestoneContentWrapper = styled.div`
  display: flex;
  padding-top: 0.8rem;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 0.1rem;
    background: ${({ theme }) => theme.color.secondary04};
    margin: 0 2.8rem 0 1.2rem;
  }
`

const StyledMilestoneWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
  position: relative;

  ${({ highlight, theme }) =>
    highlight &&
    css`
      &:before {
        content: "";
        position: absolute;
        width: calc(100% - 2rem);
        height: 100%;
        background: ${theme.color.senary05};
        left: 3rem;
        border: 0.2rem solid ${theme.color.senary03};
      }
    `}

  ${({ position }) => position && `order: ${position};`}

  &:first-of-type {
    margin-top: 1.6rem;
  }
  &:last-of-type {
    ${StyledMilestoneContentWrapper} {
      &:before {
        background: transparent;
      }
    }
  }
`

const StyledMilestoneStatus = styled.span`
  display: flex;
  align-items: center;
  position: relative;

  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.color.secondary02};

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.6rem;
  }
`

const StyledMilestoneContent = styled.div`
  padding-bottom: 1.6rem;
`

const milestoneTitleStyles = css`
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1;
  height: 2.4rem;

  padding-bottom: 0.8rem;

  &:last-of-type {
    padding-bottom: 0;
    height: initial;
  }
`
const textStyles = css`
  hyphens: auto;
  text-align: justify;
`
export {
  StyledMilestoneWrapper,
  StyledMilestoneStatus,
  StyledMilestoneContentWrapper,
  StyledMilestoneContent,
  milestoneTitleStyles,
  textStyles,
}
