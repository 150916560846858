import styled from "styled-components"

const StyledStageAnimation = styled.div`
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.mq.medium} {
    max-width: 75%;
  }

  ${({ theme }) => theme.mq.large} {
    position: absolute;
    bottom: 0;
    width: 110%;
    max-width: 110%;
    right: -5%;
  }
`

export { StyledStageAnimation }
