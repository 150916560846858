import React from "react"
import PropTypes from "prop-types"
import useWindowSize from "utils/useWindowSize"
import theme from "styles/theme"

import MobileProjectProgress from "./MobileProjectProgress"
import DesktopProjectProgress from "./DesktopProjectProgress"

const ProjectProgress = ({ sectionData }) => {
  const size = useWindowSize()

  return (
    <>
      {size.width >= theme.breakpoints.medium ? (
        <DesktopProjectProgress progressStages={sectionData} />
      ) : (
        <MobileProjectProgress progressStages={sectionData} />
      )}
    </>
  )
}

ProjectProgress.propTypes = {
  sectionData: PropTypes.arrayOf(
    PropTypes.shape({
      totalNumberOfMilestones: PropTypes.number,
      completedNumberOfMilestones: PropTypes.number,
      status: PropTypes.string,

      stage: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
      milestones: PropTypes.arrayOf(
        PropTypes.shape({
          position: PropTypes.number,
          status: PropTypes.string,
          completionDate: PropTypes.string,
          title: PropTypes.string,
          body: PropTypes.string,
        })
      ),
    })
  ).isRequired,
}

export default ProjectProgress
