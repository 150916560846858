import styled, { css } from "styled-components"

const accordionButtonStyles = css`
  font-size: ${({ theme }) => theme.font.size.md};
`

const StyledAccordionWrapper = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
`

const StyledStageAnimationWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25rem;
    height: 25rem;
  }
`

const textStyles = css`
  hyphens: auto;
  text-align: justify;
`

export {
  accordionButtonStyles,
  StyledAccordionWrapper,
  StyledStageAnimationWrapper,
  textStyles,
}
