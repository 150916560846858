import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import getProjectProgressStageType from "utils/getProjectProgressStageType"
import sortBy from "utils/sortBy"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import P from "components/atoms/P"
import SideListItem from "components/atoms/SideListItem"

import ProgressStageAnimation from "../ProgressStageAnimation"
import ProgressStagesLabel from "../ProgressStagesLabel"
import StageMilestones from "../StageMilestones"

import {
  StyledSection,
  StyledTabsWrapper,
  StyledContentWrapper,
  StyledContentColumn,
  stageDescriptionStyles,
  textStyles,
} from "./DesktopProjectProgress.styles"

const typeMap = {
  A_OPPORTUNITY_VALIDATION: "Prospecting and acquisition",
  B_PROJECT_PLANNING: "Project development",
  C_CONSTRUCTION: "Construction",
  D_PROMOTION_AND_OPERATION: "Marketing and operations",
  E_EXIT: "Exit",
}

const DesktopProjectProgress = ({ progressStages }) => {
  const [sortedStages, setSortedStages] = useState([])
  const [activeStage, setActiveStage] = useState(null)

  useEffect(() => {
    const mapedStages = progressStages.map((stage) => ({
      ...stage,
      name: stage.stage.name,
    }))
    const sorted = sortBy(mapedStages, ["name"])
    setSortedStages(sorted)
    setActiveStage(sorted[0])
  }, [progressStages])

  const handleActiveStage = (progressStage) => setActiveStage(progressStage)

  const renderProgressStage = (progressStage) => {
    if (
      progressStage.stage.name === "E_EXIT" &&
      progressStage.status !== "COMPLETE"
    ) {
      return ""
    }

    return (
      <SideListItem
        isActive={activeStage.stage.name === progressStage.stage.name}
        onClick={() => {
          handleActiveStage(progressStage)
          trackCustomEventTrigger({
            category: trackingCategoriesHelper.PROJECT_PROGRESS,
            action: "Click",
            label: `${trackingCategoriesHelper.PROJECT_PROGRESS} link(${
              typeMap[progressStage.stage.name]
            })`,
          })
        }}
        key={progressStage.stage.name}
      >
        <ProgressStagesLabel
          progressMilestones={{
            completedNumberOfMilestones:
              progressStage.completedNumberOfMilestones,
            totalNumberOfMilestones: progressStage.totalNumberOfMilestones,
            status: progressStage.status,
          }}
        />
        {getProjectProgressStageType(progressStage.stage.name)}
      </SideListItem>
    )
  }

  return (
    <StyledSection>
      {sortedStages.length > 0 && (
        <>
          <StyledTabsWrapper>
            {sortedStages.map((progressStage) =>
              renderProgressStage(progressStage)
            )}
          </StyledTabsWrapper>
          <StyledContentWrapper>
            <StyledContentColumn>
              <P extraStyles={stageDescriptionStyles}>
                {getProjectProgressStageType(activeStage.stage.name)}
              </P>
              <P extraStyles={textStyles}>{activeStage.stage.description}</P>
              <ProgressStageAnimation animationType={activeStage.stage.name} />
            </StyledContentColumn>
            <StyledContentColumn>
              {activeStage.milestones.map((milestone) => (
                <StageMilestones
                  key={milestone.position}
                  milestone={milestone}
                />
              ))}
            </StyledContentColumn>
          </StyledContentWrapper>
        </>
      )}
    </StyledSection>
  )
}

DesktopProjectProgress.propTypes = {
  progressStages: PropTypes.arrayOf(
    PropTypes.shape({
      totalNumberOfMilestones: PropTypes.number,
      completedNumberOfMilestones: PropTypes.number,
      status: PropTypes.string,
      stage: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
      milestones: PropTypes.arrayOf(
        PropTypes.shape({
          position: PropTypes.number,
          status: PropTypes.string,
          completionDate: PropTypes.string,
          title: PropTypes.string,
          body: PropTypes.string,
        })
      ),
    })
  ).isRequired,
}

export default DesktopProjectProgress
