import { useIntl } from "gatsby-plugin-intl"

const getProjectProgressStageType = (type) => {
  const { formatMessage } = useIntl()

  const typeMap = {
    A_OPPORTUNITY_VALIDATION: "opportunityValidation",
    B_PROJECT_PLANNING: "projectPlanning",
    C_CONSTRUCTION: "construction",
    D_PROMOTION_AND_OPERATION: "promotionAndOperations",
    E_EXIT: "exit",
  }
  return formatMessage({
    id: `progressStages.${typeMap[type]}`,
  })
}

export default getProjectProgressStageType
