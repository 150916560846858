import React, { useEffect, createRef } from "react"
import PropTypes from "prop-types"
import lottie from "lottie-web"

import Construction from "assets/animations/construction.json"
import Exit from "assets/animations/exit.json"
import ProjectPlanning from "assets/animations/project-planning.json"
import Promotion from "assets/animations/promotion.json"
import Validation from "assets/animations/validation.json"

import { StyledStageAnimation } from "./ProgressStageAnimation.styles"

const animations = {
  A_OPPORTUNITY_VALIDATION: Validation,
  B_PROJECT_PLANNING: ProjectPlanning,
  C_CONSTRUCTION: Construction,
  D_PROMOTION_AND_OPERATION: Promotion,
  E_EXIT: Exit,
}

const ProgressStageAnimation = ({ animationType }) => {
  const animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animations[animationType],
    })
    return () => anim.destroy()
  }, [animationType])

  return <StyledStageAnimation ref={animationContainer} key={animationType} />
}

ProgressStageAnimation.propTypes = {
  animationType: PropTypes.string.isRequired,
}

export default ProgressStageAnimation
