import React from "react"
import PropTypes from "prop-types"

import ChevronRight from "assets/icons/chevron-right.svg"

import {
  StyledSideListItemWrapper,
  StyledSideListItemContent,
} from "./SideListItem.styles"

const SideListItem = ({
  isActive,
  onClick,
  children,
  extraStyles,
  contentStyles,
  showIcon,
}) => {
  return (
    <StyledSideListItemWrapper
      isActive={isActive}
      onClick={onClick}
      extraStyles={extraStyles}
    >
      <StyledSideListItemContent extraStyles={contentStyles}>
        {children}
      </StyledSideListItemContent>
      {showIcon && <ChevronRight />}
    </StyledSideListItemWrapper>
  )
}

SideListItem.propTypes = {
  isActive: PropTypes.bool,
  showIcon: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  extraStyles: PropTypes.any,
  contentStyles: PropTypes.any,
}

SideListItem.defaultProps = {
  onClick: null,
  isActive: false,
  showIcon: true,
  extraStyles: "",
  contentStyles: "",
}

export default SideListItem
