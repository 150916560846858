import React from "react"
import PropTypes from "prop-types"

import getProjectProgressStageType from "utils/getProjectProgressStageType"

import Accordion from "components/atoms/Accordion"
import P from "components/atoms/P"

import ProgressStageAnimation from "../ProgressStageAnimation"
import StageMilestone from "../StageMilestones"

import {
  accordionButtonStyles,
  StyledAccordionWrapper,
  StyledStageAnimationWrapper,
  textStyles,
} from "./MobileProjectProgress.styles"

const MobileProjectProgress = ({ progressStages }) => {
  const renderProgressStage = (progressStage) => {
    if (
      progressStage.stage.name === "E_EXIT" &&
      progressStage.status !== "COMPLETE"
    ) {
      return ""
    }

    return (
      <Accordion
        buttonText={getProjectProgressStageType(progressStage.stage.name)}
        btnStyles={accordionButtonStyles}
        progressMilestones={{
          completedNumberOfMilestones:
            progressStage.completedNumberOfMilestones,
          totalNumberOfMilestones: progressStage.totalNumberOfMilestones,
          status: progressStage.status,
        }}
        key={progressStage.stage.name}
      >
        <StyledAccordionWrapper>
          <P extraStyles={textStyles}>{progressStage.stage.description}</P>
          <StyledStageAnimationWrapper>
            <ProgressStageAnimation animationType={progressStage.stage.name} />
          </StyledStageAnimationWrapper>

          {(progressStage.milestones || []).map((milestone) => (
            <StageMilestone key={milestone.position} milestone={milestone} />
          ))}
        </StyledAccordionWrapper>
      </Accordion>
    )
  }

  return (
    <>
      {progressStages &&
        progressStages.map((progressStage) =>
          renderProgressStage(progressStage)
        )}
    </>
  )
}

MobileProjectProgress.propTypes = {
  progressStages: PropTypes.arrayOf(
    PropTypes.shape({
      totalNumberOfMilestones: PropTypes.number,
      completedNumberOfMilestones: PropTypes.number,
      status: PropTypes.string,

      stage: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
      }),
      milestones: PropTypes.arrayOf(
        PropTypes.shape({
          position: PropTypes.number,
          status: PropTypes.string,
          completionDate: PropTypes.string,
          title: PropTypes.string,
          body: PropTypes.string,
        })
      ),
    })
  ).isRequired,
}

export default MobileProjectProgress
