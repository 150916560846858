import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import formatDate from "utils/formatDate"

import P from "components/atoms/P"

import CheckCircle from "assets/icons/check-circle.svg"
import InProgress from "assets/icons/in-progress.svg"
import Pending from "assets/icons/pending.svg"

import {
  StyledMilestoneWrapper,
  StyledMilestoneStatus,
  StyledMilestoneContentWrapper,
  StyledMilestoneContent,
  milestoneTitleStyles,
  textStyles,
} from "./StageMilestones.styles"

const getMilestoneStatusIcon = (status) => {
  const milestoneStatusIconComponents = {
    COMPLETE: <CheckCircle />,
    IN_PROGRESS: <InProgress />,
    PENDING: <Pending />,
  }
  return milestoneStatusIconComponents[status] || []
}

const StageMilestone = ({ milestone, highlight }) => {
  const { formatMessage } = useIntl()

  const getMilestoneLabel = (
    status,
    completionDate,
    completionDateDisplaySetting
  ) => {
    const milestoneLabels = {
      COMPLETE: formatDate(
        completionDate,
        completionDateDisplaySetting,
        formatMessage({
          id: `pages.portfolio.project.progress.milestonesLabels.${
            highlight ? "completedQuarter" : "completed"
          }`,
        })
      ),
      IN_PROGRESS: formatMessage({
        id: "pages.portfolio.project.progress.milestonesLabels.inProgress",
      }),
      PENDING: formatMessage({
        id: "pages.portfolio.project.progress.milestonesLabels.pending",
      }),
    }
    return milestoneLabels[status] || []
  }

  return (
    <StyledMilestoneWrapper
      key={milestone.position}
      position={milestone.position}
      highlight={highlight}
    >
      <StyledMilestoneStatus>
        {getMilestoneStatusIcon(milestone.status)}
        {getMilestoneLabel(
          milestone.status,
          milestone.completionDate,
          milestone.completionDateDisplaySetting
        )}
      </StyledMilestoneStatus>
      <StyledMilestoneContentWrapper>
        <StyledMilestoneContent>
          <P extraStyles={milestoneTitleStyles}>{milestone.title}</P>
          {milestone.body && <P extraStyles={textStyles}>{milestone.body}</P>}
        </StyledMilestoneContent>
      </StyledMilestoneContentWrapper>
    </StyledMilestoneWrapper>
  )
}

StageMilestone.propTypes = {
  milestone: PropTypes.shape({
    position: PropTypes.number,
    status: PropTypes.string,
    completionDate: PropTypes.string,
    completionDateDisplaySetting: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
  highlight: PropTypes.bool,
}

StageMilestone.defaultProps = {
  highlight: false,
}

export default StageMilestone
