import React from "react"
import { graphql } from "gatsby"
import { isEmptyRedirect } from "utils/redirect"
import PropTypes from "prop-types"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioNavigation from "components/molecules/PortfolioNavigation"
import ProjectProgress from "components/content/Portfolio/ProjectProgress"
import ClientOnly from "components/atoms/ClientOnly"

const PortfolioProgressPage = ({
  data: {
    api: { project },
  },
  pageContext: { language },
  location,
}) => {
  const { progressStages } = project || {}
  isEmptyRedirect(progressStages)

  return (
    <Layout hideFooter isNavStatic language={language}>
      <SEO title="Project progress" />
      {progressStages && (
        <ClientOnly>
          <PortfolioNavigation projectData={project} location={location} />
          <ProjectProgress sectionData={progressStages} language={language} />
        </ClientOnly>
      )}
    </Layout>
  )
}

PortfolioProgressPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      project: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string,
        progressStages: PropTypes.arrayOf(
          PropTypes.shape({
            totalNumberOfMilestones: PropTypes.number,
            completedNumberOfMilestones: PropTypes.number,
            status: PropTypes.string,

            stage: PropTypes.shape({
              name: PropTypes.string,
              description: PropTypes.string,
            }),
            milestones: PropTypes.arrayOf(
              PropTypes.shape({
                position: PropTypes.number,
                status: PropTypes.string,
                completionDate: PropTypes.string,
                title: PropTypes.string,
                body: PropTypes.string,
              })
            ),
          })
        ),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedPropertyType: PropTypes.string,
      selectedProjectType: PropTypes.string,
    }),
  }).isRequired,
}

export const query = graphql`
  query ProjectProgressPageData($apiLocale: API_Locale, $projectSlug: String!) {
    api {
      project(slug: $projectSlug, language: $apiLocale) {
        id
        slug
        name

        progressStages {
          totalNumberOfMilestones
          completedNumberOfMilestones
          status

          stage {
            name
            description
          }

          milestones {
            position
            status
            completionDate
            completionDateDisplaySetting
            title
            body
          }
        }

        ...projectTabs
      }
    }
  }
`

export default PortfolioProgressPage
