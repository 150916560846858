import styled, { css, keyframes } from "styled-components"

const arrowAnim = keyframes`
  0% { opacity: 1; transform: translateX(0)}
  100% { opacity: 0; transform: translateX(10px)}
`
const beforeStyles = css`
  content: "";
  display: block;
  width: 0.4rem;
  height: 100%;
  position: absolute;
  left: 0;
  background: ${({ theme }) => theme.color.main03};
`
const StyledSideListItemContent = styled.div`
  width: 100%;
  padding: 1.6rem 0;

  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.color.secondary02};
  transition: color 0.5s;

  ${({ extraStyles }) => extraStyles}
`

const StyledSideListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.secondary04};
  padding: 0 1.6rem 0 2.4rem;
  position: relative;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.color.secondary02};
    transition: fill 0.5s;
  }

  &:hover {
    div${StyledSideListItemContent} {
      color: ${({ theme }) => theme.color.secondary01};
    }

    svg {
      fill: ${({ theme }) => theme.color.secondary01};
    }
    &:before {
      ${beforeStyles}
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      div${StyledSideListItemContent} {
        color: ${({ theme }) => theme.color.secondary01};
        svg {
          fill: ${({ theme }) => theme.color.secondary01};
        }
      }

      &:before {
        ${beforeStyles}
      }

      & > svg {
        animation: ${arrowAnim} 0.3s 0s both;
      }
    `}
  ${({ extraStyles }) => extraStyles}
`

export { StyledSideListItemWrapper, StyledSideListItemContent }
