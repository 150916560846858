import styled, { css, keyframes } from "styled-components"

const arrowAnim = keyframes`
  0% { opacity: 1; transform: translateX(0)}
  100% { opacity: 0; transform: translateX(10px)}
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;
  height: 57.6rem;
  margin: 1.6rem auto;
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
`

const StyledTabsWrapper = styled.nav`
  flex: 0 0 26.8rem;
  margin-right: 1.6rem;
`

const StyledContentWrapper = styled.div`
  flex: 0 1 86.8rem;
  display: flex;

  ${({ theme }) => theme.mq.medium} {
    flex-direction: column;
    overflow: auto;
  }

  ${({ theme }) => theme.mq.large} {
    flex-direction: row;
    margin-left: 1.6rem;
    overflow: initial;
  }
`

const ProgressStageContent = styled.div`
  padding: 1.6rem 0;

  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.color.secondary02};
  transition: color 0.5s;
`

const ProgressStageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.secondary04};
  padding: 0 1.6rem 0 2.4rem;
  position: relative;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.color.secondary02};
    transition: fill 0.5s;
  }

  &:hover {
    div${ProgressStageContent} {
      color: ${({ theme }) => theme.color.secondary01};
    }

    > svg {
      fill: ${({ theme }) => theme.color.secondary01};
    }
  }

  ${({ activeStage }) =>
    activeStage &&
    css`
      div${ProgressStageContent} {
        color: ${({ theme }) => theme.color.secondary01};
      }

      &:before {
        content: "";
        display: block;
        width: 0.4rem;
        height: 100%;
        position: absolute;
        left: 0;
        background: ${({ theme }) => theme.color.main03};
      }

      & > svg {
        animation: ${arrowAnim} 0.3s 0s both;
      }
    `}
`

const StyledContentColumn = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${({ theme }) => theme.mq.medium} {
    padding-left: ${({ theme }) => theme.layout.mobilePadding};
    overflow: initial;
  }

  ${({ theme }) => theme.mq.large} {
    margin-right: -0.6rem;
    padding-right: 0.6rem;
    flex: 0 1 44rem;

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.secondary03};
    }
    &:first-of-type {
      padding-left: 0;
      padding-right: 0.8rem;
    }
    &:last-of-type {
      padding-left: 0.8rem;
      overflow: auto;
    }
  }
`

const stageDescriptionStyles = css`
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  margin-bottom: 0.8rem;
`
const textStyles = css`
  hyphens: auto;
  text-align: justify;
`
export {
  StyledSection,
  StyledTabsWrapper,
  StyledContentWrapper,
  ProgressStageWrapper,
  ProgressStageContent,
  StyledContentColumn,
  stageDescriptionStyles,
  textStyles,
}
