import { useIntl } from "gatsby-plugin-intl"

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
]

const formatDate = (date, dateSetup, label = "") => {
  const { formatMessage } = useIntl()

  const getMonths = months.map((month) => {
    return formatMessage({
      id: `months.${month}`,
    })
  })

  const d = new Date(date)
  const year = d.getFullYear()
  const day = d.getDate()
  const monthIndex = d.getMonth()
  const monthName = getMonths[monthIndex]
  const showLabel = label ? `${label}: ` : ""

  const dateDisplaySetting = (setting) => {
    const dateSettings = {
      FULL_DATE: `${showLabel}${year} \u{2013} ${monthName} ${day}`,
      MONTH_AND_YEAR: `${showLabel}${year} \u{2013} ${monthName}`,
      YEAR: `${showLabel}${year}`,
      NO_DATE: `${label || ""}`,
    }

    if (setting) return dateSettings[setting]
    return `${year} \u{2013} ${monthName} ${day}`
  }

  return dateDisplaySetting(dateSetup)
}

export default formatDate
